/*
full-layout

@copyright      Copyright 2015, AceWare, Inc. (http://www.aceware.co.jp)
*/
/* 
    Created on : 2015/02/19, 17:45:09
    Author     : Takayuki Shinbo <t.shinbo@aceware.co.jp>
*/
html,
body {
  padding-top: 0;
  height: 100%;
  background-color: #2B2B2B;
  background-image: -webkit-linear-gradient(top, #2B2B2B 0%, #4A4A4A 100%);
  background-image: -o-linear-gradient(top, #2B2B2B 0%, #4A4A4A 100%);
  background-image: linear-gradient(to bottom, #2B2B2B 0%, #4A4A4A 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff2b2b2b', endColorstr='#ff4a4a4a', GradientType=0);
  background-attachment: fixed !important;
}
#bland-logo {
  position: fixed;
  top: 15px;
  left: 30px;
  color: #fff;
  font-size: 48px;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
  font-family: "Roboto Thin";
}
@media (max-width: 750px) {
  #bland-logo {
    display: none;
  }
}
.wrapper {
  height: 100%;
}
.signin-wrapper {
  display: table;
  width: 100%;
  height: 100%;
  min-height: 100%;
}
.signin-wrapper-inner {
  display: table-cell;
  vertical-align: middle;
}
.signin-wrapper .page-header {
  margin-top: 10px;
}
.signin-wrapper .page-header {
  font-family: "Roboto Thin";
}
.signin-wrapper-inner .panel {
  z-index: 9999;
}
.signin-wrapper-inner .panel .panel-body {
  -webkit-box-shadow: 0px 0px 30px 30px #f0f0f0 inset;
  box-shadow: 0px 0px 30px 30px #f0f0f0 inset;
  border-radius: 8px;
}
html,
body {
  background: none;
  background-color: #fff;
}
.signin-wrapper-inner .panel .panel-body {
  box-shadow: none;
}
.signin-wrapper {
  position: relative;
  overflow: hidden;
}
.signin-wrapper:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 120%;
  height: 80%;
  margin: -27vh -10vw 0;
  background: #03A9F4;
  -webkit-transform-origin: left center;
  -ms-transform-origin: left center;
  transform-origin: left center;
  -webkit-transform: rotate(-3deg);
  -ms-transform: rotate(-3deg);
  transform: rotate(-3deg);
}
