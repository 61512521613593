@import "../../full-layout";

@base-color: #03A9F4;

html, body {
    background: none;
    background-color: #fff;
}

.signin-wrapper-inner {
    .panel .panel-body {
        box-shadow: none;
    }
}

.signin-wrapper {
    position: relative;
    overflow: hidden;
}

.signin-wrapper:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 120%;
    height: 80%;
    margin: -27vh -10vw 0;
    background: @base-color;
    -webkit-transform-origin: left center;
    -ms-transform-origin: left center;
    transform-origin: left center;
    -webkit-transform: rotate(-3deg);
    -ms-transform: rotate(-3deg);
    transform: rotate(-3deg);
}
